<template>
  <div id="users">
    <!-- 用户列表卡片区 -->
    <el-card class="box-card" style=" overflow-x: scroll;">

      <el-form :inline="true" ref="form" :model="queryMap" label-width="90px" size="small">
        <el-form-item label="签约项目">
          <el-cascader
              v-model="queryMap.projectId"
              :options="options"
              :props="{ checkStrictly: true }"
              clearable></el-cascader>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="queryMap.type" placeholder="请选择类型">
            <el-option
                v-for="item in typeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="custom-font" type="primary" @click="projectChange()">选择</el-button>
            <el-button class="custom-font" type="success" @click="confirm()">确认</el-button>
          <el-button @click="cancel">返 回</el-button>
        </el-form-item>
      </el-form>
      <div style="height: 100%; width: 1600px;">
        <el-row>
          <el-col :span="3"><h3> {{ projectName }}</h3></el-col>
          <el-col :span="3"><h3> {{ typeName }}</h3></el-col>
        </el-row>

        <div v-if="typeName === '报表'">
          <el-row style="margin-top: 30px">
            <el-col :span="24">
              <el-checkbox v-model="checked1" label="工数记入提醒(T1)" border></el-checkbox>
            </el-col>
            <el-card class="box-card" style="width: 500px; margin-top: 60px" v-if="checked1">
              <div slot="header" class="clearfix">
                <span>工数记入提醒</span>
              </div>
              <el-form ref="form" :model="subSettings.group_dailyprompt" label-width="90px">
                <el-form-item label="频度">
                  <el-select
                      clearable
                      multiple
                      v-model="subSettings.group_dailyprompt.daysOfWeek"
                      placeholder="请选择频度">
                    <el-option
                        v-for="organ in pOptions"
                        :key="organ.id"
                        :label="organ.name"
                        :value="organ.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="时点">
                  <el-time-picker
                      editable
                      v-model="subSettings.group_dailyprompt.sendTime"
                      :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                      placeholder="请选择时点">
                  </el-time-picker>
                </el-form-item>
                <el-form-item label="不受信邮箱">
                  <el-input
                      type="textarea"
                      placeholder="请输入不受信邮箱(换行分割)"
                      v-model="subSettings.group_dailyprompt.mails"
                      show-word-limit
                      rows="5"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </el-card>
          </el-row>

          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <el-checkbox v-model="checked2" label="项目工数日报(T2)" border></el-checkbox>
            </el-col>
            <el-col :span="8">
              <el-checkbox v-model="checked3" label="项目工数周报(T2)" border></el-checkbox>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px" v-if="checked2 || checked3">
            <el-col :span="8" v-if="checked2">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>组织工数日报</span>
                </div>
                <el-form ref="form" :model="settings.group_spent_time_everyday" label-width="90px"
                >
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="settings.group_spent_time_everyday.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="settings.group_spent_time_everyday.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="受信邮箱">
                    <el-input
                        type="textarea"
                        placeholder="请输入受信邮箱(换行分割)"
                        v-model="settings.group_spent_time_everyday.mails"
                        show-word-limit
                        rows="5"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="不统计成员">
                    <el-select
                        clearable
                        multiple
                        v-model="settings.group_spent_time_everyday.excludeMember"
                        placeholder="请选择不统计成员">
                      <el-option
                          v-for="organ in ruserOptions"
                          :key="organ.id"
                          :label="organ.lastname"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
            <el-col :span="8" v-if="checked3">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>组织工数周报</span>
                </div>
                <el-form ref="form" :model="settings.group_spent_time_weekly" label-width="90px">
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="settings.group_spent_time_weekly.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="settings.group_spent_time_weekly.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                  <el-form-item label="受信邮箱">
                    <el-input
                        type="textarea"
                        placeholder="请输入受信邮箱(换行分割)"
                        v-model="settings.group_spent_time_weekly.mails"
                        show-word-limit
                        rows="5"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="不统计成员">
                    <el-select
                        clearable
                        multiple
                        v-model="settings.group_spent_time_weekly.excludeMember"
                        placeholder="请选择不统计成员">
                      <el-option
                          v-for="organ in ruserOptions"
                          :key="organ.id"
                          :label="organ.lastname"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>

            <el-col :span="8" v-if="checked3 || checked2">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>组织工数其他设置</span>
                </div>
                <el-form ref="form" :model="settings" label-width="90px">
                  <el-form-item label="统计项目">
                    <el-select
                        clearable
                        multiple
                        v-model="target_prjects"
                        placeholder="请选择统计项目">
                      <el-option
                          v-for="organ in subProjectOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
          </el-row>

          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <el-checkbox v-model="checked4" label="当天开始任务(T3)" border></el-checkbox>
            </el-col>
            <el-col :span="8">
              <el-checkbox v-model="checked5" label="当天结束任务(T3)" border></el-checkbox>
            </el-col>
          </el-row>

          <el-row style="margin-top: 30px" v-if="checked4">
            <el-col :span="8">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>任务预定开始通知</span>
                </div>
                <el-form ref="form" :model="subSettings.PJ_duration_StartDay" label-width="90px">
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="subSettings.PJ_duration_StartDay.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="subSettings.PJ_duration_StartDay.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>

            <el-col :span="8" v-if="checked5">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>任务预定完了通知</span>
                </div>
                <el-form ref="form" :model="subSettings.PJ_duration_EndDay" label-width="90px">
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="subSettings.PJ_duration_EndDay.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="subSettings.PJ_duration_EndDay.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>

          </el-row>

          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <el-checkbox v-model="checked6" label="前一周的项目进度周报(T4)" border></el-checkbox>
            </el-col>
          </el-row>

          <el-row style="margin-top: 20px" v-if="checked6">
            <el-col :span="8">
              <el-card class="box-card" style="width: 500px;">
                <div slot="header" class="clearfix">
                  <span>前一周的项目进度周报</span>
                </div>
                <el-form ref="form" :model="subSettings.PJ_week_report_weekly" label-width="90px">
                  <el-form-item label="频度">
                    <el-select
                        clearable
                        multiple
                        v-model="subSettings.PJ_week_report_weekly.daysOfWeek"
                        placeholder="请选择频度">
                      <el-option
                          v-for="organ in pOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="时点">
                    <el-time-picker
                        editable
                        v-model="subSettings.PJ_week_report_weekly.sendTime"
                        :picker-options="{
                    selectableRange: '06:00:00 - 20:30:00'
                    }"
                        placeholder="请选择时点">
                    </el-time-picker>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <div v-if="typeName === '页面'">
          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <el-checkbox v-model="checked1" label="看板" border></el-checkbox>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="24">
              <el-checkbox v-model="checked2" label="查看工数数据(T2)" border></el-checkbox>
            </el-col>
              <el-card class="box-card" style="width: 500px; margin-top: 60px"  v-if="checked2">
                <div slot="header" class="clearfix">
                  <span>统计项目设置</span>
                </div>
                <el-form ref="form" :model="settings" label-width="90px">
                  <el-form-item label="统计项目">
                    <el-select
                        clearable
                        multiple
                        v-model="target_prjects"
                        placeholder="请选择统计项目">
                      <el-option
                          v-for="organ in subProjectOptions"
                          :key="organ.id"
                          :label="organ.name"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="不统计成员">
                    <el-select
                        clearable
                        multiple
                        v-model="excludeMember"
                        placeholder="请选择不统计成员">
                      <el-option
                          v-for="organ in ruserOptions"
                          :key="organ.id"
                          :label="organ.lastname"
                          :value="organ.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-card>
          </el-row>
          <el-row style="margin-top: 20px">

            <el-col :span="12">
              <el-checkbox v-model="checked3" label="查看当天开始任务(T3)" border></el-checkbox>
            </el-col>

          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="24">
              <el-checkbox v-model="checked4" label="查看当天结束任务(T3)" border></el-checkbox>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :span="24">
              <el-checkbox v-model="checked5" label="查看周报(T4)" border></el-checkbox>
            </el-col>
          </el-row>
        </div>

      </div>
    </el-card>
  </div>

</template>

<script>
export default {
  name: "setup",
  data() {
    return {
      queryMap: {},
      projectOptions: [],
      options: [{
        value: '项目1',
        label: '项目1',
        children: [{
          value: '项目2',
          label: '项目2',
          children: [{
            value: '项目3',
            label: '项目3'
          }, {
            value: '项目4',
            label: '项目4'
          }, {
            value: '项目5',
            label: '项目5'
          }, {
            value: '项目6',
            label: '项目6'
          }]
        }]
      }],
      typeName: '',
      typeOptions: [
        {id: 1, name: "页面"},
        {id: 2, name: "报表"},
      ],
      settings: {
        group_spent_time_everyday: {},
        group_spent_time_weekly: {}
      },
      subSettings: {
        PJ_duration_StartDay: {},
        PJ_duration_EndDay: {},
        PJ_week_report_weekly: {},
        group_dailyprompt:{}
      },
      pOptions: [
        {id: 1, name: "周日"},
        {id: 2, name: "周一"},
        {id: 3, name: "周二"},
        {id: 4, name: "周三"},
        {id: 5, name: "周四"},
        {id: 6, name: "周五"},
        {id: 7, name: "周六"},
      ],
      ruserOptions: [],
      subProjectOptions: [],
      shortName: '',
      target_prjects: [],
      subProjectName: '',
      projectName: '',
      checked1: '',
      checked2: '',
      checked3: '',
      checked4: '',
      checked5: '',
      checked6: '',
      excludeMember: [],
    }
  },
  created() {
    this.getProjects()
  },
  methods: {
    /**
     * 返回按钮
     */
    cancel() {
      this.$router.push( {path: "/system/pageSetupInfo"});
    },
    /**
     * 获取跟项目设置信息
     * @returns {Promise<void>}
     */
    async getReportSetting() {
      const {data: res} = await this.$http.get("/system/report/setting?projectId=" + this.queryMap.projectId);
      console.log(res.data);
      if (Object.keys(res.data).length !== 0) {
        this.settings = res.data
        if (res.data.group_spent_time_everyday.daysOfWeek != null) {
          this.settings.group_spent_time_everyday.daysOfWeek = res.data.group_spent_time_everyday.daysOfWeek.includes(',') ? res.data.group_spent_time_everyday.daysOfWeek.split(',').map(Number) : [res.data.group_spent_time_everyday.daysOfWeek].map(Number)
        }
        if (res.data.group_spent_time_weekly.daysOfWeek != null) {
          this.settings.group_spent_time_weekly.daysOfWeek = res.data.group_spent_time_weekly.daysOfWeek.includes(',') ? res.data.group_spent_time_weekly.daysOfWeek.split(',').map(Number) : [res.data.group_spent_time_weekly.daysOfWeek].map(Number)
        }
        if (res.data.group_spent_time_everyday.sendTime != null) {
          this.settings.group_spent_time_everyday.sendTime = this.timeAsDate(res.data.group_spent_time_everyday.sendTime)
        }
        if (res.data.group_spent_time_weekly.sendTime != null) {
          this.settings.group_spent_time_weekly.sendTime = this.timeAsDate(res.data.group_spent_time_weekly.sendTime)
        }
        if (res.data.group_spent_time_everyday.extraSettingMap != null && res.data.group_spent_time_everyday.extraSettingMap.mails != undefined && res.data.group_spent_time_everyday.extraSettingMap.mails != null) {
          this.$set(this.settings.group_spent_time_everyday, "mails", res.data.group_spent_time_everyday.extraSettingMap.mails.replaceAll(";", "\n"))
        }
        if (res.data.group_spent_time_weekly.extraSettingMap != null && res.data.group_spent_time_weekly.extraSettingMap.mails != undefined && res.data.group_spent_time_weekly.extraSettingMap.mails != null) {
          this.$set(this.settings.group_spent_time_weekly, "mails", res.data.group_spent_time_weekly.extraSettingMap.mails.replaceAll(";", "\n"))
        }
        if (res.data.group_spent_time_weekly.extraSettingMap != null) {
          this.$set(this.settings.group_spent_time_weekly, "excludeMember", res.data.group_spent_time_weekly.extraSettingMap.exclude_member)
        }
        if (res.data.group_spent_time_everyday.extraSettingMap != null) {
          this.$set(this.settings.group_spent_time_everyday, "excludeMember", res.data.group_spent_time_everyday.extraSettingMap.exclude_member)
        }
        this.shortName = res.data.group_spent_time_everyday.rootProjectShortName
        this.target_prjects = res.data.group_spent_time_everyday.extraSettingMap.target_prjects
      } else {
        this.settings = {
          group_spent_time_everyday: {},
          group_spent_time_weekly: {}
        }
        this.shortName = ''
        this.target_prjects = []
      }
    },

    /**
     * 获取子项目设置信息
     */
    async getSubReportSetting() {
      const {data: res} = await this.$http.get("/system/report/setting?projectId=" + this.queryMap.subProjectId);
      console.log(res.data);
      if (Object.keys(res.data).length !== 0) {
        this.subSettings = res.data
        if (res.data.PJ_duration_StartDay.daysOfWeek != null) {
          this.subSettings.PJ_duration_StartDay.daysOfWeek = res.data.PJ_duration_StartDay.daysOfWeek.includes(',') ? res.data.PJ_duration_StartDay.daysOfWeek.split(',').map(Number) : [res.data.PJ_duration_StartDay.daysOfWeek].map(Number)
        }
        if (res.data.PJ_duration_EndDay.daysOfWeek != null) {
          this.subSettings.PJ_duration_EndDay.daysOfWeek = res.data.PJ_duration_EndDay.daysOfWeek.includes(',') ? res.data.PJ_duration_EndDay.daysOfWeek.split(',').map(Number) : [res.data.PJ_duration_EndDay.daysOfWeek].map(Number)
        }
        if (res.data.PJ_duration_StartDay.sendTime != null) {
          this.subSettings.PJ_duration_StartDay.sendTime = this.timeAsDate(res.data.PJ_duration_StartDay.sendTime)
        }
        if (res.data.PJ_duration_EndDay.sendTime != null) {
          this.subSettings.PJ_duration_EndDay.sendTime = this.timeAsDate(res.data.PJ_duration_EndDay.sendTime)
        }
      } else {
        this.subSettings = {
          PJ_duration_StartDay: {},
          PJ_duration_EndDay: {}
        }
      }

      for (let index = 0; index < this.subProjectOptions.length; index++) {
        if (this.queryMap.subProjectId == this.subProjectOptions[index].id) {
          this.subProjectName = this.subProjectOptions[index].name
        }
      }
    },

    /**
     * 公司确定按钮
     */
    async confirm() {
      // if (this.projectName == '') {
      //   this.$message.warning('请选择公司');
      //   return
      // }

      if ((this.settings.group_spent_time_everyday.daysOfWeek !== undefined &&
              this.settings.group_spent_time_everyday.daysOfWeek !== null &&
              this.settings.group_spent_time_everyday.daysOfWeek !== '' &&
              this.settings.group_spent_time_everyday.sendTime !== undefined &&
              this.settings.group_spent_time_everyday.sendTime !== null &&
              this.settings.group_spent_time_everyday.sendTime !== '') ||
          (this.settings.group_spent_time_weekly.daysOfWeek !== undefined &&
              this.settings.group_spent_time_weekly.daysOfWeek !== null &&
              this.settings.group_spent_time_weekly.daysOfWeek !== '' &&
              this.settings.group_spent_time_weekly.sendTime !== undefined &&
              this.settings.group_spent_time_weekly.sendTime !== null &&
              this.settings.group_spent_time_weekly.sendTime !== '')
      ) {
        if (this.target_prjects.length == 0) {
          this.$message.warning('请选择签约项目');
          return
        }
      }

      if (this.shortName == '') {
        this.$message.warning('请填写公司简称');
        return
      }

      const loading = this.$loading({
        lock: true,
        text: '执行中, 请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let bodys = this.getBody();
      let request = {
        type: 0,
        shortName: this.shortName,
        reportSettings: bodys
      }
      const {data: res} = await this.$http.put("/system/" + this.queryMap.projectId + "/report/setting", request);
      if (res.status === 0) {
        this.$message({
          message: '配置编辑成功',
          type: 'success'
        });
      } else {
        this.$message.error('配置编辑失败');
      }

      this.getReportSetting()
      loading.close()
    },

    /**
     * 子项目确定按钮
     */
    async SubConfirm() {

      if (this.subProjectName == '') {
        this.$message.warning('请选择子项目');
        return
      }

      if (this.shortName == '' || this.shortName == null) {
        this.$message.warning('请填写公司简称');
        return
      }

      const loading = this.$loading({
        lock: true,
        text: '执行中, 请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let bodys = this.getSubBody();
      let request = {
        type: 1,
        reportSettings: bodys,
        rootProjectId: this.queryMap.projectId
      }
      const {data: res} = await this.$http.put("/system/" + this.queryMap.subProjectId + "/report/setting", request);
      if (res.status === 0) {
        this.$message({
          message: '配置编辑成功',
          type: 'success'
        });
      } else {
        this.$message.error('配置编辑失败');
      }

      this.getSubReportSetting()
      loading.close()
    },
    /**
     * 公司项目切换
     */
    projectChange() {

      this.projectName = this.queryMap.projectId[0]
      for (let i = 0; i < this.typeOptions.length; i++) {
        if (this.queryMap.type == this.typeOptions[i].id) {
          this.typeName = this.typeOptions[i].name
        }
      }
      this.getProjectMember()
      this.getReportSetting()
      this.getOneProjects()
    },
    /**
     * 公司数据整理
     * @returns {*}
     */
    getBody() {
      let bodys = [];
      var body = {}
      body.id = this.settings.group_spent_time_everyday.id
      body.projectId = this.queryMap.projectId
      body.reportType = 'group_spent_time_everyday'
      if (this.settings.group_spent_time_everyday.daysOfWeek != undefined && this.settings.group_spent_time_everyday.daysOfWeek != null) {
        body.daysOfWeek = this.settings.group_spent_time_everyday.daysOfWeek.length > 1 ? this.settings.group_spent_time_everyday.daysOfWeek.join(',') : this.settings.group_spent_time_everyday.daysOfWeek[0]
      }
      if (this.settings.group_spent_time_everyday.sendTime != undefined && this.settings.group_spent_time_everyday.sendTime != null) {
        body.sendTime = this.settings.group_spent_time_everyday.sendTime.toLocaleTimeString()
      }
      var value = {
        exclude_member: this.settings.group_spent_time_everyday.excludeMember
      }
      if (this.settings.group_spent_time_everyday.mails != undefined && this.settings.group_spent_time_everyday.mails != null) {
        value.mails = this.settings.group_spent_time_everyday.mails.replaceAll("\n", ";")
      }
      value.target_prjects = this.target_prjects
      body.extraSetting = JSON.stringify(value)
      bodys.push(body)

      var body = {}
      body.id = this.settings.group_spent_time_weekly.id
      body.projectId = this.queryMap.projectId
      body.reportType = 'group_spent_time_weekly'
      if (this.settings.group_spent_time_weekly.daysOfWeek != undefined && this.settings.group_spent_time_weekly.daysOfWeek != null) {
        body.daysOfWeek = this.settings.group_spent_time_weekly.daysOfWeek.length > 1 ? this.settings.group_spent_time_weekly.daysOfWeek.join(',') : this.settings.group_spent_time_weekly.daysOfWeek[0]
      }
      if (this.settings.group_spent_time_weekly.sendTime != undefined && this.settings.group_spent_time_weekly.sendTime != null) {
        body.sendTime = this.settings.group_spent_time_weekly.sendTime.toLocaleTimeString()
      }
      var value = {
        exclude_member: this.settings.group_spent_time_weekly.excludeMember
      }
      if (this.settings.group_spent_time_weekly.mails != undefined && this.settings.group_spent_time_weekly.mails != null) {
        value.mails = this.settings.group_spent_time_weekly.mails.replaceAll("\n", ";")
      }
      value.target_prjects = this.target_prjects
      body.extraSetting = JSON.stringify(value)
      bodys.push(body)
      return bodys;
    },

    /**
     * 子项目数据整理
     */
    getSubBody() {
      let bodys = [];
      var body = {}
      body.id = this.subSettings.PJ_duration_StartDay.id
      body.projectId = this.queryMap.subProjectId
      body.reportType = 'PJ_duration_StartDay'
      if (this.subSettings.PJ_duration_StartDay.daysOfWeek != undefined && this.subSettings.PJ_duration_StartDay.daysOfWeek != null) {
        body.daysOfWeek = this.subSettings.PJ_duration_StartDay.daysOfWeek.length > 1 ? this.subSettings.PJ_duration_StartDay.daysOfWeek.join(',') : this.subSettings.PJ_duration_StartDay.daysOfWeek[0]
      }
      if (this.subSettings.PJ_duration_StartDay.sendTime != undefined && this.subSettings.PJ_duration_StartDay.sendTime != null) {
        body.sendTime = this.subSettings.PJ_duration_StartDay.sendTime.toLocaleTimeString()
      }
      var value = {
        exclude_member: this.subSettings.PJ_duration_StartDay.excludeMember
      }
      if (this.subSettings.PJ_duration_StartDay.mails != undefined && this.subSettings.PJ_duration_StartDay.mails != null) {
        value.mails = this.subSettings.PJ_duration_StartDay.mails.replaceAll("\n", ";")
      }
      body.extraSetting = JSON.stringify(value)
      bodys.push(body)

      var body = {}
      body.id = this.subSettings.PJ_duration_EndDay.id
      body.projectId = this.queryMap.subProjectId
      body.reportType = 'PJ_duration_EndDay'
      if (this.subSettings.PJ_duration_EndDay.daysOfWeek != undefined && this.subSettings.PJ_duration_EndDay.daysOfWeek != null) {
        body.daysOfWeek = this.subSettings.PJ_duration_EndDay.daysOfWeek.length > 1 ? this.subSettings.PJ_duration_EndDay.daysOfWeek.join(',') : this.subSettings.PJ_duration_EndDay.daysOfWeek[0]
      }
      if (this.subSettings.PJ_duration_EndDay.sendTime != undefined && this.subSettings.PJ_duration_EndDay.sendTime != null) {
        body.sendTime = this.subSettings.PJ_duration_EndDay.sendTime.toLocaleTimeString()
      }
      var value = {
        exclude_member: this.subSettings.PJ_duration_EndDay.excludeMember
      }
      if (this.subSettings.PJ_duration_EndDay.mails != undefined && this.subSettings.PJ_duration_EndDay.mails != null) {
        value.mails = this.subSettings.PJ_duration_EndDay.mails.replaceAll("\n", ";")
      }
      body.extraSetting = JSON.stringify(value)
      bodys.push(body)
      return bodys;
    },
    /**
     * 获取公司列表
     * @returns {Promise<void>}
     */
    async getProjects() {
      const {data: res} = await this.$http.get("/system/projects?type=0&userId=" + LocalStorage.get("USER").id);
      this.projectOptions = res.data
    },

    /**
     * 获取子项目列表
     * @returns {Promise<void>}
     */
    async getOneProjects() {
      const {data: res} = await this.$http.get("/system/subProjects?type=0&projectId=" + this.queryMap.projectId);
      this.subProjectOptions = res.data
      console.log(this.subProjectOptions)

    },

    /**
     * 获取项目下成员
     * @returns {Promise<void>}
     */
    async getProjectMember() {
      const {data: res} = await this.$http.get("/system/" + this.queryMap.projectId + "/members");
      this.ruserOptions = res.data
    },

    /**
     * 时间处理
     * @param time
     * @returns {Date}
     */
    timeAsDate(time) {
      const parts = time.split(':');
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(parts[2], 10);
      // 创建一个新的Date对象，设置为今天的日期，并设置指定的时、分、秒
      return new Date(new Date().setHours(hours, minutes, seconds, 0));
    }


  }
}
</script>

<style scoped>
.el-card ::v-deep .el-card__header {
  padding: 8px 8px
}
</style>